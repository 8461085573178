import React, { useState } from "react";
import { Space, Table, Tag } from "antd";
import cdata from "../cuttoffdata.json";
import { Input, Button, Layout, Badge } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

let name = "hello";

export const Table1 = () => {
	const [year2021, setYear2021] = useState(true);

	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						this.handleSearch(selectedKeys, confirm, dataIndex)
					}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => this.handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							this.setState({
								searchText: selectedKeys[0],
								searchedColumn: dataIndex
							});
						}}
					>
						Filter
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
				: "",
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			)
	});

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const columns = [
		{
			title: "College Code",
			dataIndex: "College Code",
			key: "name",
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters
			}) => {
				return (
					<Layout style={{ padding: "20px 20px", backgroundColor: "white" }}>
						<Input
							size="large"
							style={{ marginBottom: "10px" }}
							autoFocus
							placeholder="Search By Name"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						/>
						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								onClick={() => {
									confirm();
								}}
								type="primary"
							>
								Search
							</Button>
							<Button
								type="danger"
								onClick={() => {
									clearFilters();
									confirm();
								}}
							>
								Clear
							</Button>
						</div>
					</Layout>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record["College Code"] === parseInt(value);
			}
		},
		{
			title: "College Name",
			dataIndex: "College Name",
			key: "College Name",
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters
			}) => {
				return (
					<Layout style={{ padding: "20px 20px", backgroundColor: "white" }}>
						<Input
							size="large"
							style={{ marginBottom: "10px" }}
							autoFocus
							placeholder="Search By Name"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						/>
						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								onClick={() => {
									confirm();
								}}
								type="primary"
							>
								Search
							</Button>
							<Button
								type="danger"
								onClick={() => {
									clearFilters();
									confirm();
								}}
							>
								Clear
							</Button>
						</div>
					</Layout>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record[`College Name`]
					.toLowerCase()
					.includes(value.toLowerCase());
			}
		},
		{
			title: "Branch Code",
			dataIndex: "Branch Code",
			key: "Branch Code",
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters
			}) => {
				return (
					<Layout style={{ padding: "20px 20px", backgroundColor: "white" }}>
						<Input
							size="large"
							style={{ marginBottom: "10px" }}
							autoFocus
							placeholder="Search By Name"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						/>
						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								onClick={() => {
									confirm();
								}}
								type="primary"
							>
								Search
							</Button>
							<Button
								type="danger"
								onClick={() => {
									clearFilters();
									confirm();
								}}
							>
								Clear
							</Button>
						</div>
					</Layout>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record[`Branch Code`]
					.toLowerCase()
					.includes(value.toLowerCase());
			}
		},

		{
			key: "year",
			title: year2021 ? "Year - 2021" : "Year - 2020",
			filters: [
				{
					text: "Year 2021",
					value: 2021
				},
				{
					text: "Year 2020",
					value: 2020
				}
			],
			onFilter: (value, record) => {
				setYear2021(!year2021);
				return record.year == value;
			},
			children: [
				{
					title: "OC",
					dataIndex: "OC",
					key: "OC"
					// sorter: (a, b) => a.OC - b.OC
				},
				{
					title: "BC",
					dataIndex: "BC",
					key: "BC"
					// sorter: (a, b) => a.BC - b.BC
				},
				{
					title: "BCM",
					dataIndex: "BCM",
					key: "BCM"
					// sorter: (a, b) => a.BCM - b.BCM
				},
				{
					title: "MBCV",
					dataIndex: "MBCV",
					key: "MBCV"
					// sorter: (a, b) => a.MBCV - b.MNCV
				},
				{
					title: "MBCDNC",
					dataIndex: "MBCDNC",
					key: "MBCDNC"
					// sorter: (a, b) => a.MBCDNC - b.MBCDNC
				},
				{
					title: "MBC",
					dataIndex: "MBC",
					key: "MBC"
					// sorter: (a, b) => a.MBC - b.MBC
				},
				{
					title: "SC",
					dataIndex: "SC",
					key: "SC"
					// sorter: (a, b) => a.SC - b.SC
				},
				{
					title: "SCA",
					dataIndex: "SCA",
					key: "SCA"
					// sorter: (a, b) => a.SCA - b.SCA
				},
				{
					title: "ST",
					dataIndex: "ST",
					key: "ST"
					// sorter: (a, b) => a.ST - b.ST
				}
			]
		}
	];

	return (
		<div style={{ padding: "20px", overflowX: "scroll", width: "100vw" }}>
			<Table columns={columns} dataSource={cdata} />
		</div>
	);
};
