import logo from "./logo.svg";
import "./App.css";
import { Navbar } from "./navbar/Navbar";
import { Table1 } from "./table/table";

function App() {
	return (
		<div className="App">
			<Navbar></Navbar>
			<Table1></Table1>
		</div>
	);
}

export default App;
