import React from "react";
import "./navbar.css";
import { UserOutlined } from "@ant-design/icons";
import logo from "../933px-TamilNadu_Logo.svg.png";
import { useNavigate } from "react-router-dom";
import "antd/dist/antd.css";

export const Navbar = () => {
	// const navigate = useNavigate()

	return (
		<div className="navbarcont">
			<div className="navbarinnercont">
				<div
					className="titandlogo"
					onClick={() => {
						// navigate("/");
					}}
				>
					<img src={logo} alt="" height={50} />
					<span>TNEA 2022 Cutoffs and Ranks </span>
				</div>
				{/* <div className="navlogindiv">
					<UserOutlined size={15} />
					<button
						onClick={() => {
							// navigate("/login");
						}}
					>
						Login
					</button>
				</div> */}
			</div>
		</div>
	);
};
